import { Children, type ReactNode } from "react";
import invariant from "tiny-invariant";

export default function () {
	return (
		<div className="mx-auto text-center grid grid-cols-1 md:grid-cols-2 gap-x-4 md:gap-x-10 gap-y-5 w-full">
			<HoverLink
				url={"/faq"}
				imageURL="/images/book.jpg"
				alt="Cover of my book"
				label="My book!"
			/>
			<HoverLink
				url={"/faq"}
				imageURL="/images/designjournal.jpg"
				alt="Cover of my workbook"
				label="My workbook!"
			/>
			<HoverLink
				url={"https://zoehong-shop.fourthwall.com"}
				imageURL="/images/mugs.jpg"
				alt="My coffee mugs"
				label="My mugs!"
			/>

			<Pair>
				<HoverLink
					alt="YouTube"
					imageURL="/images/youtube.jpg"
					label="Watch my videos"
					url={"https://www.youtube.com/zoehongteaches"}
				/>
				<HoverLink
					alt="Patreon"
					imageURL="/images/patreon.jpg"
					label="Take my classes"
					url={"https://www.patreon.com/zoehong"}
				/>
			</Pair>

			<Pair>
				<HoverLink
					alt="My book recommendations"
					imageURL="/images/bookrecs.jpg"
					label="Book recommendations"
					url={
						"https://www.amazon.com/shop/zoehong/list/3MV9ITJSXC48Q?ref_=aip_sf_list_spv_ofs_mixed_d"
					}
				/>
				<HoverLink
					alt="My tool recommendations"
					imageURL="/images/toolrecs.jpg"
					label="Tool recommendations"
					url={"https://www.amazon.com/shop/zoehong"}
				/>
			</Pair>

			<Pair>
				<HoverLink
					alt="Consult"
					imageURL="/images/consults.jpg"
					label="Book a private consult"
					url={"https://calendly.com/zoe-hong"}
				/>
				<HoverLink
					alt="Shopify"
					imageURL="/images/shopify.jpg"
					label="My shop"
					url={"https://shop.zoehong.com/"}
				/>
			</Pair>
		</div>
	);
}

function HoverLink({
	url,
	imageURL,
	alt,
	label,
}: {
	url: string;
	imageURL: string;
	alt: string;
	label: string;
}) {
	return (
		<a
			href={url}
			title={label}
			target="_blank"
			className="block"
			rel="noreferrer"
		>
			<div className="aspect-video">
				<img
					src={imageURL}
					alt={alt}
					className="object-cover rounded-xs shadow-xs"
				/>
			</div>
			<div className="my-2 text-xl text-center font-semibold">{label}</div>
		</a>
	);
}

function Pair({
	children,
}: {
	children: ReactNode;
}) {
	invariant(
		Children.toArray(children).length >= 1 &&
			Children.toArray(children).length <= 2,
		"Must have one or two children",
	);

	return (
		<div className="grid grid-cols-2 gap-x-4 md:gap-x-10 gap-y-5 w-full">
			{children}
		</div>
	);
}
